import React, { useState, useEffect } from "react";
import Navbar from "../components/globaux/navbar";
import Footer from "../components/globaux/footer";
import Data from "../resumeData.json";
import SEO from "../context/seo";


const Marques = () => {
    const [marquesData, setMarquesData] = useState([]);

    useEffect(() => {
        // Chargez les données depuis le fichier JSON
        setMarquesData(Data.marquesData);
    }, []);

    return (
        <div>
            <SEO
                title="Cathy Pour Elle | Marques"
                description="Découvrez les marques avec lesquelles nous collaborons, choisies avec soin pour leur innovation, leur éthique et leur savoir-faire. Chaque collection que vous trouvez chez nous raconte une histoire, reflétant notre engagement envers la diversité des styles et des inspirations."
                keywords="Cathy Pour Elle, marques, collaboration, mode, style, éthique"
                canonicalUrl="https://cathypourelle.fr/Marques/"
            />
            <Navbar />
            <div className="DivMarques">
                <p className="paragraphe">Les marques avec lesquelles Cathy collabore sont choisies avec soin, privilégiant l'innovation, l'éthique et le savoir-faire. Chaque collection que vous trouvez chez nous raconte une histoire, reflétant notre engagement envers la diversité des styles et des inspirations. <br /> <br />

                    Cathy croit que la mode devrait être une expression de soi, c'est pourquoi elle travaille avec des marques qui partagent sa philosophie. Chez Cathy Pour Elle, chaque vêtement qu'elle sélectionne est une déclaration de style, une invitation à explorer votre individualité.</p>
                <div className="DivGrid">
                    {marquesData.map((item, index) => (
                        <div key={index} className="GridItem">
                            <img src={require(`../assets/marques/${item.image}`)} alt={item.title} />
                            <p>{item.title}</p>
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </div >
    );
};

export default Marques;