import React from "react";
import { Link } from 'react-router-dom';

const BoxLink = () => {
    const isPCOrLarger = window.innerWidth >= 790;

    return (
        <div className={`containers ${isPCOrLarger ? 'row' : 'column'}`}>
            <Link to="/Boutique" className={`box1 ${isPCOrLarger ? 'PC' : 'mobile'}`}>
                <p className="texteMobile">Boutique</p>
                <p className="text">La Boutique</p>
                <p className="text2">Boutique</p>
            </Link>

            <Link to="/Marques" className={`box2 ${isPCOrLarger ? 'PC' : 'mobile'}`}>
                <p className="texteMobile">Marque</p>
                <p className="text">Marque</p>
                <p className="text2">Marque</p>
            </Link>

            <Link to="/Contact" className={`box3 ${isPCOrLarger ? 'PC' : 'mobile'}`}>
                <p className="texteMobile">Contact</p>
                <p className="text">Contact</p>
                <p className="text2">Contact</p>
            </Link>
        </div>
    );
};

export default BoxLink;
