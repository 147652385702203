import React from "react";
import { Link } from 'react-router-dom';


import ReactGA from 'react-ga4';

const handleSocialLinkClick = (category, action, label) => {
    ReactGA.event({
        category: category,
        action: action,
        label: label,
    });
};

const Footer = () => {
    const currentYear = new Date().getFullYear();


    return (
        <footer className="footer">
            <h1 className="droits">
                {currentYear} |
                <Link to="/MentionLegale">Mentions Légales</Link>
                <p>Réalisation et Conception du site — Manier Valentin</p>
            </h1>
            <div className="divreseaux">
                <a className="insta" href="https://www.instagram.com/cpourellesouchez/" onClick={() => handleSocialLinkClick("Footer", "Clic Instagram", "Lien Instagram")}>
                    INSTAGRAM
                </a>
                <a className="facebook" href="https://www.facebook.com/profile.php?id=100063510765939" onClick={() => handleSocialLinkClick("Footer", "Clic Facebook", "Lien Facebook")}>
                    FACEBOOK
                </a>
            </div>
        </footer>
    );
};

export default Footer;
