import React from "react";
import Navbar from "../components/globaux/navbar";
import LogoAnim from "../components/pageHome/logoanim";
import Footer from "../components/globaux/footer";
import LienImage from "../components/pageHome/lienImage";
import SEO from "../context/seo";

const Profil = () => {
    return (
        <div className="divHome">
            <SEO
                title="Cathy Pour Elle | Boutique de vêtement pour femme."
                description="Découvrez notre collection de vêtements tendance pour femmes. Achetez en ligne les dernières tendances de la mode féminine."
                keywords="vêtements, femmes, mode, tendance, C, pour, elle, Cathy, Pour, Elle, souchez, boutique, C pour elle, boutique"
                canonicalUrl="https://cathypourelle.fr/Home/"
            />
            <Navbar />
            <div className="pageHome">
                <LogoAnim />
                <LienImage />
            </div>
            <Footer />
        </div>
    );
};

export default Profil;
