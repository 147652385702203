// Routes.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import Boutique from "../pages/Boutique";
import Marques from "../pages/Marques";
import Contact from "../pages/Contact";
import MentionLegale from "../pages/MentionLegale";

const Chemin = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="Home" element={<Home />} />
                <Route path="Boutique" element={<Boutique />} />
                <Route path="Marques" element={<Marques />} />
                <Route path="Contact" element={<Contact />} />
                <Route path="MentionLegale" element={<MentionLegale />} />
            </Routes>
        </Router>
    );
};

export default Chemin;
