import React from "react";
import Navbar from "../components/globaux/navbar";
import Footer from "../components/globaux/footer";
import image1 from "../assets/images/image1.webp";
import image222 from "../assets/images/image222.webp";
import SEO from "../context/seo";


const Profil = () => {
    return (
        <div className="divBoutique">
            <SEO
                title="Cathy Pour Elle | Boutique"
                description="Explorez la collection soigneusement sélectionnée où chaque pièce raconte une histoire de style intemporel. Cathy Pour Elle, c'est bien plus qu'une boutique de vêtements, c'est un lieu où chacun peut se sentir chez soi."
                keywords="Cathy Pour Elle, boutique, vêtements, mode, élégance, style, C, pour, elle, Cathy, Pour, Elle, souchez, boutique, C pour elle"
                canonicalUrl="https://cathypourelle.fr/Boutique/"
            />
            <Navbar />
            <div className="pageBoutique">
                <div className="upperPage">
                    <p>Bienvenue chez <strong>Cathy Pour Elle</strong>, une histoire de style et d'élégance depuis 2008. Fondée par Cathy Demarque, la boutique a grandi avec la vision de fusionner la <strong>mode</strong> et le raffinement. <br /><br />
                        En 2012, elle a franchi une nouvelle étape en s'installant dans un espace plus spacieux, élargissant ainsi la sélection pour mieux vous servir. Guidés par la passion de Cathy, la boutique incarne l'engagement envers la qualité et le service personnalisé.
                        Explorez la collection soigneusement sélectionnée, où chaque pièce raconte une histoire de style intemporel. Merci de faire partie de la voyage chez Cathy Pour Elle, où la mode prend vie.</p>
                    <img className="image1" src={image1} alt="" />
                </div>

                <div className="downPage">
                    <img className="image22" src={image222} alt="" />
                    <p>Le concept va au-delà des <strong>vêtements</strong> ; c'est une expérience de partage et de style. <br /><br />
                        Chez elle, chaque client est accueilli avec un sourire, chaque achat est une célébration de l'individualité. Cathy croit en la création d'une communauté où la passion pour la <strong>mode</strong> crée des liens durables. <br /><br />
                        Découvrez un espace où le shopping devient une expérience joyeuse, où la convivialité et le partage sont au cœur de la approche. Cathy Pour Elle, c'est bien plus qu'une boutique de vêtements, c'est un lieu où chacun peut se sentir chez soi.</p>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Profil;
