import React from 'react';
import Navbar from "../components/globaux/navbar";
import Footer from "../components/globaux/footer";
import SEO from "../context/seo";

const Title = ({ children }) => (
    <h1 className="title">{children}</h1>
);

const Paragraph = ({ children }) => (
    <p className="paragraph">{children}</p>
);

const Strong = ({ children }) => <strong className="strong">{children}</strong>;

const Link = ({ href, target = '_blank', children }) => (
    <a
        href={href}
        target={target}
        className="link"
    >
        {children}
    </a>
);

const App = () => (
    <div>
        <Navbar />

        <SEO
            title="Cathy Pour Elle | Mentions Légales"
            description="Consultez nos mentions légales pour obtenir des informations sur l'éditeur, l'hébergement, la propriété intellectuelle, les liens hypertextes, les cookies et la protection des données personnelles."
            keywords="Cathy Pour Elle, mentions légales, éditeur, hébergement, propriété intellectuelle, cookies, protection des données personnelles"
            canonicalUrl="https://cathypourelle.fr/MentionsLegales/"
        />


        <div className='MentionLegale'>

            <Title>MENTIONS LEGALES :</Title>

            <Paragraph>
                Conformément aux dispositions des articles 6-III et 19 de la Loi n° 2004-575 du 21 juin 2004 pour la Confiance dans
                l'économie numérique, dite L.C.E.N., nous portons à la connaissance des utilisateurs et visiteurs du site :{' '}
                <Link href="http://cathypourelle.fr">cathypourelle.fr</Link> les informations suivantes :
            </Paragraph>

            <Paragraph>
                <Strong>ÉDITEUR</Strong>
            </Paragraph>

            <Paragraph>
                Le site <Link href="http://cathypourelle.fr">cathypourelle.fr</Link> est la propriété exclusive de{' '}
                <Strong>EURL CATHY POUR ELLE</Strong>, qui l'édite.
            </Paragraph>

            <Paragraph>
                <Strong>CATHY POUR ELLE</Strong>
                <br />
                <Strong>EURL</Strong> au capital de <Strong>7 500,00 €</Strong>
                <br />
                Tél : <Strong>03 21 45 46 03</Strong>
                <br />
                Adresse : <Strong>69 RUE CARNOT</Strong> <Strong>62153 Souchez</Strong>
                <br />
                Immatriculée au Registre du Commerce et des Sociétés de <Strong>503 195 299 R.C.S. Arras</Strong> sous le numéro{' '}
                <Strong>50319529900022</Strong>
                <br />
                Numéro TVA intracommunautaire : <Strong>FR32503195299</Strong>
                <br />
                Adresse de courrier électronique : <Strong>contact@cathypourelle.fr</Strong>
                <br />
                <br />
                Directeur de la publication : <Strong>Valentin Manier</Strong>
                <br />
                Contactez le responsable de la publication : <Strong>manier.valentin3@gmail.com</Strong>
            </Paragraph>

            <Paragraph>
                <Strong>HÉBERGEMENT</Strong>
            </Paragraph>

            <Paragraph>
                Le site est hébergé par <Strong>HOSTINGER 1 Lordou Vironos Street 6023 Larnaca, Chypre</Strong>
            </Paragraph>

            <Paragraph>
                <Strong>DESCRIPTION DES SERVICES FOURNIS</Strong>
            </Paragraph>

            <Paragraph>
                Le site <Link href="http://cathypourelle.fr">cathypourelle.fr</Link> a pour objet de fournir une information
                concernant l’ensemble des activités de la société. Le propriétaire du site s’efforce de fournir sur le site{' '}
                <Link href="http://cathypourelle.fr">cathypourelle.fr</Link> des informations aussi précises que possible. Toutefois,
                il ne pourra être tenu responsable des omissions, des inexactitudes et des carences dans la mise à jour, qu’elles soient
                de son fait ou du fait des tiers partenaires qui lui fournissent ces informations. Toutes les informations proposées
                sur le site <Link href="http://cathypourelle.fr">cathypourelle.fr</Link> sont données à titre indicatif, sont non
                exhaustives, et sont susceptibles d’évoluer. Elles sont données sous réserve de modifications ayant été apportées
                depuis leur mise en ligne.
            </Paragraph>

            <Paragraph>
                <Strong>PROPRIÉTÉ INTELLECTUELLE ET CONTREFAÇONS</Strong>
            </Paragraph>

            <Paragraph>
                Le propriétaire du site est propriétaire des droits de propriété intellectuelle ou détient les droits d’usage sur
                tous les éléments accessibles sur le site, notamment les textes, images, graphismes, logo, icônes, sons, logiciels…
                Toute reproduction, représentation, modification, publication, adaptation totale ou partielle des éléments du site,
                quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable à l'email :{' '}
                <Link href="mailto:contact@cathypourelle.fr">contact@cathypourelle.fr</Link>. Toute exploitation non autorisée du site
                ou de l’un quelconque de ces éléments qu’il contient sera considérée comme constitutive d’une contrefaçon et
                poursuivie conformément aux dispositions des articles L.335-2 et suivants du Code de Propriété Intellectuelle.
            </Paragraph>

            <Paragraph>
                <Strong>LIENS HYPERTEXTES ET COOKIES</Strong>
            </Paragraph>

            <Paragraph>
                Le site <Link href="http://cathypourelle.fr">cathypourelle.fr</Link> contient un certain nombre de liens hypertextes
                vers d’autres sites (partenaires, informations …) mis en place avec l’autorisation de le propriétaire du site .
                Cependant, le propriétaire du site n’a pas la possibilité de vérifier le contenu des sites ainsi visités et décline
                donc toute responsabilité de ce fait quand aux risques éventuels de contenus illicites. L’utilisateur est informé
                que lors de ses visites sur le site <Link href="http://cathypourelle.fr">cathypourelle.fr</Link>, un ou des cookies
                sont susceptible de s’installer automatiquement sur son ordinateur. Un cookie est un fichier de petite taille, qui ne
                permet pas l’identification de l’utilisateur, mais qui enregistre des informations relatives à la navigation d’un
                ordinateur sur un site. Les données ainsi obtenues visent à faciliter la navigation ultérieure sur le site, et ont
                également vocation à permettre diverses mesures de fréquentation. Le paramétrage du logiciel de navigation permet
                d’informer de la présence de cookie et éventuellement, de refuser de la manière décrite à l’adresse suivante :{' '}
                <Link href="http://www.cnil.fr">www.cnil.fr</Link> Le refus d’installation d’un cookie peut entraîner
                l’impossibilité d’accéder à certains services. L’utilisateur peut toutefois configurer son ordinateur de la manière
                suivante, pour refuser l’installation des cookies : Sous Internet Explorer : onglet outil / options internet. Cliquez
                sur Confidentialité et choisissez Bloquer tous les cookies. Validez sur Ok. Sous Netscape : onglet édition /
                préférences. Cliquez sur Avancées et choisissez Désactiver les cookies. Validez sur Ok.
            </Paragraph>

            <Paragraph>
                <Strong>PROTECTION DES BIENS ET DES PERSONNES - GESTION DES DONNÉES PERSONNELLES</Strong>
            </Paragraph>

            <Paragraph>
                Utilisateur : Internaute se connectant, utilisant le site susnommé :{' '}
                <Link href="http://cathypourelle.fr">cathypourelle.fr</Link> En France, les données personnelles sont notamment
                protégées par la loi n° 78-87 du 6 janvier 1978, la loi n° 2004-801 du 6 août 2004, l'article L. 226-13 du Code pénal
                et la Directive Européenne du 24 octobre 1995.
            </Paragraph>

            <Paragraph>
                Sur le site <Link href="http://cathypourelle.fr">cathypourelle.fr</Link>, le propriétaire du site ne collecte des
                informations personnelles relatives à l'utilisateur que pour le besoin de certains services proposés par le site{' '}
                <Link href="http://cathypourelle.fr">cathypourelle.fr</Link>. L'utilisateur fournit ces informations en toute
                connaissance de cause, notamment lorsqu'il procède par lui-même à leur saisie. Il est alors précisé à l'utilisateur
                du site <Link href="http://cathypourelle.fr">cathypourelle.fr</Link> l’obligation ou non de fournir ces informations.
                Conformément aux dispositions des articles 38 et suivants de la loi 78-17 du 6 janvier 1978 relative à
                l’informatique, aux fichiers et aux libertés, tout utilisateur dispose d’un droit d’accès, de rectification, de
                suppression et d’opposition aux données personnelles le concernant. Pour l’exercer, adressez votre demande à{' '}
                <Link href="http://cathypourelle.fr">cathypourelle.fr</Link> par email :{' '}
                <Link href="mailto:contact@cathypourelle.fr">contact@cathypourelle.fr</Link> ou en effectuant sa demande écrite et
                signée, accompagnée d’une copie du titre d’identité avec signature du titulaire de la pièce, en précisant l’adresse à
                laquelle la réponse doit être envoyée.
            </Paragraph>

            <Paragraph>
                Aucune information personnelle de l'utilisateur du site <Link href="http://cathypourelle.fr">cathypourelle.fr</Link> n'est
                publiée à l'insu de l'utilisateur, échangée, transférée, cédée ou vendue sur un support quelconque à des tiers. Seule
                l'hypothèse du rachat du site <Link href="http://cathypourelle.fr">cathypourelle.fr</Link> à le propriétaire du site et
                de ses droits permettrait la transmission des dites informations à l'éventuel acquéreur qui serait à son tour tenu de
                la même obligation de conservation et de modification des données vis à vis de l'utilisateur du site{' '}
                <Link href="http://cathypourelle.fr">cathypourelle.fr</Link>.
            </Paragraph>

            <Paragraph>
                Le site <Link href="http://cathypourelle.fr">cathypourelle.fr</Link> est en conformité avec le RGPD voir notre politique
                RGPD{' '}
                <Link href="https://cathypourelle.fr/MentionsLegales">https://cathypourelle.fr/MentionsLegales</Link>.
            </Paragraph>

            <Paragraph>Les bases de données sont protégées par les dispositions de la loi du 1er juillet 1998 transposant la directive 96/9 du 11 mars 1996 relative à la protection juridique des bases de données.</Paragraph>
        </div>
        <Footer />
    </div>
);

export default App;
