import React from "react";

const AnimatedText = () => {
    return (
        <div className="boite">
            <div className="svg-container">
                <svg className="texte">
                    <text x="50%" y="50%" textAnchor="middle" alignmentBaseline="central">
                        C Pour Elle
                    </text>
                </svg>
            </div>
        </div>
    );
};
export default AnimatedText;
