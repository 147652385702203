import React, { useState } from "react";
import Navbar from "../components/globaux/navbar";
import Footer from "../components/globaux/footer";
import Horaire from "../components/pageContact/horaires";
import SEO from "../context/seo";
import ReactGA from 'react-ga4';

const handleSocialLinkClick = (category, action, label) => {
    ReactGA.event({
        category: category,
        action: action,
        label: label,
    });
};

const Contact = () => {
    const [isActive, setIsActive] = useState(false);

    const toggleMenu = () => {
        setIsActive(!isActive);
    };

    // const containerMenuRef = useRef(null);

    // const toggleMenu = () => {
    //     const containerMenu = containerMenuRef.current;
    //     if (containerMenu) {
    //         containerMenu.classList.toggle('active');
    //     }
    // };

    // useEffect(() => {
    //     const containerMenu = document.querySelector('.container-menu');
    //     const btnMenu = document.querySelector('.btn-menu');

    //     const toggleMenu = () => {
    //         containerMenu.classList.toggle('active');
    //     };

    //     btnMenu.addEventListener('click', toggleMenu);

    //     return () => {
    //         btnMenu.removeEventListener('click', toggleMenu);
    //     };
    // }, []);

    return (
        <div>
            <SEO
                title="Cathy Pour Elle | Contact"
                description="Contactez-nous pour toute demande d'information ou de collaboration. Retrouvez nos coordonnées, horaires et localisation. Nous sommes là pour répondre à vos questions."
                keywords="Cathy Pour Elle, contact, adresse, téléphone, e-mail, horaires, localisation, Souchez"
                canonicalUrl="https://cathypourelle.fr/Contact/"
            />
            <Navbar />
            <div className="pageContact">
                <p className="Information">Pour toute demande d'information ou de collaboration, n'hésitez pas à me contacter :
                    <span> <u>Adresse : </u> 69 Rue Carnot, 62153 Souchez </span>
                    <span> <u>Numéro de téléphone : </u> 06 30 72 90 84</span></p>

                {/* <div className="container-menu" ref={containerMenuRef}> */}
                {/* <div className="container-menu"> */}
                <div className={`container-menu ${isActive ? 'active' : ''}`}>
                    <div className="btn-menu" onClick={() => toggleMenu()}>
                        <div className="ligne"></div>
                    </div>

                    <div className="blob blob-2">
                        <a href="https://www.instagram.com/cpourellesouchez/" target="_blank" rel="noreferrer" onClick={() => handleSocialLinkClick("Contact", "Clic Instagram", "Lien Instagram")}>
                            <img src={require(`../assets/logo/LogoIntagram.webp`)} alt="logo linkedin" className="icone" />
                        </a>
                    </div>
                    <div className="blob blob-4">
                        <a href="https://www.facebook.com/p/C-pour-Elle-100063510765939/" target="_blank" rel="noreferrer" onClick={() => handleSocialLinkClick("Contact", "Clic Facebook", "Lien Facebook")}>
                            <img src={require(`../assets/logo/LogoFacebook.webp`)} alt="Logo Git" className="icone" />
                        </a>
                    </div>
                </div>
                <div className="divHoraire">
                    <Horaire />
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d189.06418672930772!2d2.741956181160748!3d50.390150499488406!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47dd39836c12b851%3A0xa999e0f3e368893!2sCathy%20Pour%20Elle!5e0!3m2!1sfr!2sfr!4v1697717579207!5m2!1sfr!2sfr"
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        title="Intégration Carte Google Maps"
                        alt="Carte Google Maps qui pointe sur la boutique Cathy Pour Elle."
                    ></iframe>
                </div>
            </div >
            <Footer />
        </div >
    );
};

export default Contact;
