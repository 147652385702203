import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../assets/logo/Logo.webp';


function Navbar() {
    const [isMobileNavActive, setIsMobileNavActive] = useState(false);

    const toggleNav = () => {
        setIsMobileNavActive(!isMobileNavActive);
    };

    return (
        <div>
            <nav>
                <div className="center-logo">
                    <div className="logo">
                        <Link to="/Home"><img className='LogoImage' src={Logo} alt="logo" /></Link>

                    </div>
                </div>
                <div className="center-nav">
                    <ul>
                        <li>
                            <Link to="/Home">Acceuil</Link>
                        </li>
                        <li>
                            <Link to="/Boutique">La Boutique</Link>
                        </li>
                        <li>
                            <Link to="/Marques">Marque</Link>
                        </li>
                        <li>
                            <Link to="/Contact">Contact</Link>
                        </li>
                    </ul>
                </div>
                <div className={`hamburger ${isMobileNavActive ? 'hamburger-active' : ''}`} onClick={toggleNav}>
                    <span className="line"></span>
                    <span className="line"></span>
                    <span className="line"></span>
                </div>
            </nav>
            <div className={`menubar ${isMobileNavActive ? 'active' : ''}`}>
                <ul>
                    <li>
                        <Link to="/">Acceuil</Link>
                    </li>
                    <li>
                        <Link to="/Boutique">Boutique</Link>
                    </li>
                    <li>
                        <Link to="/Marques">Marque</Link>
                    </li>
                    <li>
                        <Link to="/Contact">Contact</Link>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Navbar;