import React from "react";

const Horaire = () => {
  const scheduleData = [
    { day: "Lundi :", times: ["Fermé"] },
    { day: "Mardi :", times: ["09:30–12:00", "14:30–19:00"] },
    { day: "Mercredi :", times: ["09:30–12:00", "14:30–19:00"] },
    { day: "Jeudi :", times: ["09:30–12:00", "14:30–19:00"] },
    { day: "Vendredi :", times: ["09:30–12:00", "14:30–19:00"] },
    { day: "Samedi :", times: ["09:30–12:00", "14:30–19:00"] },
    { day: "Dimanche :", times: ["Fermé"] },
  ];

  return (
    <div className="schedule-container">
      <table className="schedule-table">
        <thead>
          <tr>
            <th>Jour</th>
            <th>Horaires</th>
          </tr>
        </thead>
        <tbody>
          {scheduleData.map((item, index) => (
            <tr key={index}>
              <td>{item.day}</td>
              <td>
                <ul className="time-list">
                  {item.times.map((time, idx) => (
                    <li key={idx} className="time-slot">
                      {time}
                    </li>
                  ))}
                </ul>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Horaire;
